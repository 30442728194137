import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Sitio KPTA S.R.L en construcción.
        </p>
        <a href="mailto:info@kpta.com.ar">info@kpta.com.ar</a>
        
      </header>
    </div>
  );
}

export default App;
